body{
    font-family: arial;
}


.home-wrap {
    display: flex;
    flex: 1;
    background-color: white;
    flex-direction: column;
    padding-top: 8rem;
}

.home-content {
    width: 100%;
    margin: 0 auto;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
}