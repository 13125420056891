.container {
  width: 100%;
  height: 100%;
  padding-top: 80px;
}
.header_view {
  margin: 0 auto;
  width: 795px;
  padding-top: 75px;
}
.header_title {
  font-size: 30px;
  font-weight: 600;
  color: #162533;
  line-height: 42px;
}
.header_tip {
  font-size: 16px;
  font-weight: 400;
  color: #696E7E;
  line-height: 22px;
  margin-top: 6px;
}
.view_note {
  display: flex;
  align-items: center;
  margin-top: 47px;
}
.view_text {
  font-size: 16px;
  color: #40A6FF;
  line-height: 22px;
  cursor:pointer;
}
.render_view {
  margin: 0 auto;
  width: 795px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-top: 47px;
}
.title {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #333333;
  line-height: 56px;
}
.select_wrap {
  display: flex;
  padding-left: 101px;
  margin-top: 24px;
}
.displeasure_btn  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 289px;
  height: 55px;
  border-radius: 25px;
  background-color: rgba(243, 245, 248, 1);
  cursor:pointer;
}

.satisfied_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 289px;
  height: 55px;
  border-radius: 25px;
  background-color: rgba(255, 133, 51, 0.10);
  cursor:pointer;
}
.satisfied_icon {
  width: 30px;
  height: 30px;
  margin-right: 11px;
}
.satisfied_text {
  font-size: 15px;
  font-weight: 500;
  color: #162533;
  line-height: 21px;
}
.goin {
  width: 9px;
  height: 15px;
  margin-left: 6px;
}
.select_tip {
  text-align: center;
  font-size: 14px;
  color: #A4ACB4;
  line-height: 20px;
  margin-top: 50px;
}
.select_one {
  font-size: 14px;
  color: #40A6FF;
  line-height: 20px;
  margin-top: 58px;
  margin-left: 101px;
}
.eval_contain {
  width: 100%;
  padding: 0 101px 0 101px;
}
.eval_item {
  width: 100%;
  padding-bottom: 19px;
  border-bottom: 1px solid #F3F5F8;
}
.tags_view {
  width: 100%;
  padding-bottom: 40px;
  border-bottom: 1px solid #F3F5F8;
}
.tags_item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 23px;
}
.tags_title {
  flex-grow:0;
  flex-shrink:0;
  font-size: 16px;
  color: #162533;
  line-height: 22px;
  margin-right: 20px;
}
.tags_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.eval_targer {
  font-size: 16px;
  font-weight: 600;
  color: #162533;
  line-height: 22px;
  margin-top: 45px;
}
.active_tag {
  height: 26px;
  font-size: 14px;
  color: white;
  line-height: 26px;
  text-align: center;
  border-radius: 2px;
  background-color: #40A6FF;
  border: 1px solid #40A6FF;
  outline: none;
  padding: 0 10px 0 10px;
  margin-right: 12px;
  margin-bottom: 10px;
  cursor:pointer;
}
.tag_btn {
  height: 26px;
  font-size: 14px;
  color: #696E7E;
  line-height: 26px;
  text-align: center;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #A4ACB4;
  outline: none;
  padding: 0 10px 0 10px;
  margin-right: 12px;
  margin-bottom: 10px;
  cursor:pointer;
}

.submit_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 99;
}
.submit_btn {
  width: 593px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #40A6FF;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor:pointer;
}
