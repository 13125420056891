html,body{
  height: 100%;
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  padding-bottom: 10rem;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.license_img {
  
  width: 119.1rem;
  height: 84.2rem;
}