.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: transparent !important;
  z-index: 9999;
}

.wechat-login-iframe {
  height: 39rem;
  padding-left: 1rem;
}
.ant-modal {
  width: 50rem !important;
}
.login-modal{
  width: 100%;
  
  background: #ffffff;
  border-radius: .8rem;
  padding-top: 2.6rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.close-wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.close-item {
  padding: 1.6rem;
  padding-bottom: 0;
  margin-top: 1rem;
  margin-right: 1.1rem;
  margin-bottom: .2rem;
}
.close-icon{
  width: 1.6rem;
  height: 1.6rem;
}
.content-wrap {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.logo-wrap{
  display: flex;
  align-items: center;
  margin-bottom: 3.3rem;
}
.login-icon {
  width: 4.2rem;
  height: 4.2rem;
  margin-right: .8rem;
}
.title-wrap {
  display: flex;
  flex-direction: column;
}
.modal-title {
  font-size: 2rem;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.sub-title {
  font-size: 1.4rem;
  color: #696E7E;
  line-height: 2rem;
}
.input-wrap {
  width: 100%;
  height: 5rem;
  border: .1rem solid #DCDFE6;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
  overflow: hidden;
}
.input-wrap-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  border: .1rem solid #DCDFE6;
  border-top: 0;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  overflow: hidden;
}
.num-input {
  width: 100%;
  height: 5rem;
  font-size: 1.8rem;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 1.4rem;
}
.code-input {
  flex: 1;
  height: 5rem;
  font-size: 1.6rem;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 1.4rem;
}
.line-icon {
  width: .1rem;
  height: 2rem;
  background-color: #DCDFE6;
}
.code-btn {
  height: 5rem;
  font-size: 1.4rem;
  flex-shrink: 0;
  padding: 0 2rem;
  text-align: center;
  color: #162533;
  background-color: transparent;
  border: none;
  outline: none;
  cursor:pointer;
}
.err-tip {
  height: 1.7rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #F16765;
  line-height: 1.7rem;
  margin-top: 1.1rem;
}
.submit-button {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  color: #FFFFFF;
  background: #40A6FF;
  border-radius: .4rem;
  border: none;
  outline: none;
  margin-top: .8rem;
  cursor:pointer;
}
.another-way {
  text-align: center;
  font-size: 1.4rem;
  color: #A4ACB4;
  margin-top: 3rem;
}
.wechat-wrap {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 1.3rem;
}
.wechat-item {
  width: 3.7rem;
  height: 3rem;
  cursor:pointer;
}
.wechat {
  width: 100%;
  height: 100%;
}

.num-input::-webkit-outer-spin-button,
.num-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    margin: 0; 
}
.num-input {
  -moz-appearance:textfield;
}


.agree-wrap {
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
}
.agree-icon {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: .2rem;
  border: .1rem solid #DCDFE6;
  position: relative;
  margin-right: .6rem;
}
.agree-text {
  font-size: 1.4rem;
  color: #162533;
}
.agree-link {
  font-size: 1.4rem;
  color: #0081FF;
  cursor: pointer;
}
.agree-enabled {
  border-color: #0081FF;
}
.agree-enabled::before{
  content: "";
  width: .8rem;
  height: 1.4rem;
  border-color: #0081FF;
  border-style: solid;
  border-width: 0 .2rem .2rem 0;
  transform: rotate(45deg);
  position: absolute;
  left: .4rem;
}