.to_top_view {
    width: 5rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 10rem;
    right: 10rem;
    z-index: 99;
}
.download_wrap {
    width: 5rem;
    height: 5rem;
    position: relative;
    
}
.download_item {
    display: flex;
    padding: 2.1rem;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: -43rem;
}
.item {
    margin-right: 2.8rem;
}
.title {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.8rem;
    margin-bottom: 1.2rem;
}
.download_icon {
    width: 18rem;
    height: 18rem;
}

.fillIn ,
.to_top,
.download {
    width: 5rem;
    height: 5rem;
    cursor:pointer;
}

.to_top {
    margin-top: 2rem;
}