.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
}
.inv_top {
  width: 100%;
  height: auto;
}
.inv_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 12rem;
  padding-bottom: 8rem;
}
.title {
  font-size: 3.6rem;
  font-weight: 600;
  color: #000000;
  line-height: 5rem;
  border-bottom: .6rem solid #40A6FF;
}
.process_wrap {
  /* padding-left: 12%;
  padding-right: 12%; */
  padding-top: 4.8rem;
  width: 100%;
}
.process_view {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.process_info {
  width: 22rem;
}
.process_tag {
  width: 22rem;
  height: 18rem;
  background: #FFFFFF linear-gradient(315deg, rgba(64,166,255,0.4) 0%, rgba(86,174,255,0) 100%);
  border-radius: 8px;
  border: .1rem solid rgba(213,236,255,0.49);
}
.tag_1 {
  font-size: 4.8rem;
  font-weight: 600;
  color: #40A6FF;
  line-height: 6.5rem;
  padding-left: 2rem;
  padding-top: 2rem;
}
.tag_2 {
  font-size: 3.2rem;
  font-weight: 600;
  color: #000000;
  line-height: 4.5rem;
  padding-left: 2rem;
  padding-top: 1rem;
}
.m_t_20  {
  margin-top: 2rem;
}
.process_text  {
  font-size: 1.6rem;
  color: #000000;
  line-height: 2.4rem;
  margin-top: 3.2rem;
}
.arrow  {
  width: 2.3rem;
  height: 1.2rem;
  margin-top: 8.4rem;
}
.type_tag {
  width: 48%;
  height: 15.6rem;
  background: #FFFFFF linear-gradient(315deg, rgba(64,166,255,0.2) 0%, rgba(86,174,255,0) 100%);
  border-radius: .8rem;
  border: .1rem solid #D5ECFF;
}
.type_1 {
  font-size: 2.6rem;
  font-weight: 600;
  color: #000000;
  line-height: 3.7rem;
  padding-top: 3.2rem;
  padding-left: 3.6rem;
}
.type_2 {
  font-size: 1.6rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 2.2rem;
  padding-top: 2.4rem;
  padding-left: 3.6rem;
}
.m_t_50 {
  margin-top: 5rem;
}
.title_wrap  {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11.4rem;
}
.title_text{
  font-size: 3.6rem;
  font-weight: 600;
  color: #000000;
  line-height: 5rem;
}
.title_line {
  width: 14.4rem;
  height: .6rem;
  background: #40A6FF;
  margin-top: .8rem;
}
.card_view {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 28rem;
  background: #FFFFFF linear-gradient(315deg, rgba(64,166,255,0.2) 0%, rgba(86,174,255,0) 100%);
  border-radius: .8rem;
  border: .1rem solid #D5ECFF;
  padding: 3.2rem 3.6rem;
}
.flex_3 {
  flex: 3;
}
.flex_2 {
  flex: 2;
}
.card_title {
  font-size: 2.6rem;
  font-weight: 600;
  color: #000000;
  line-height: 3.7rem;
}
.m_t_24 {
  margin-top: 2.4rem;
}
.card_tip {
  font-size: 1.6rem;
  color: #000000;
  line-height: 3rem;
}
.h_310 {
  height: 31rem;
}
.h_462 {
  height: 46.2rem;
}
.m_t_36 {
  margin-top: 3.6rem;
}
.m_l_26 {
  margin-left: 2.6rem;
}