.footer_view {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(220,223,230,0.3);
}
.footer_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  padding-top: 4rem;
  padding-bottom: 8rem;
  padding-left: 12%;
  padding-right: 12%;
}
.info_text {
  font-size: 2rem;
  font-weight: 600;
  color: #000000;
  line-height: 4rem;
}
.tip_text {
  font-size: 1.6rem;
  color: #000000;
  font-weight: 400;
  line-height: 3rem;
  cursor: pointer;
}
.tip_text:hover {
  color: #000000;
}
.footer_img_wrap {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.footer_lz2 {
  width: 3.1rem;
  height: 3.7rem;
  cursor: pointer;
  margin-right: 1rem;
}
.footer_lz3 {
  width: 3.6rem;
  height: 4rem;
  cursor: pointer;
  margin-right: 1rem;
}
.point_none { 
  cursor: default;
}