.container {
  display: flex;
  align-items: center;
}
.title {
  font-size: 1.6rem;
  color: #162533;
  line-height: 2.2rem;
  margin-right: 2rem;
}
.img_view {
  width: 3rem;
  height: 3rem;
  margin-right: 5rem;
  cursor:pointer;
}
.eval_text {
  font-size: 1.6rem;
  font-weight: 600;
  color: #40A6FF;
  line-height: 2.2rem;
}

.tags_view {
  display: inline-flex;
  flex-wrap: wrap;
  /*width: 50%;*/
}
.active_tag {
  height: 3.2rem;
  font-size: 1.4rem;
  color: #FF8533;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 1.6rem;
  background-color: rgba(255, 133, 51, 0.1);
  /*border: 1px solid #40A6FF;*/
  outline: none;
  padding: 0 1rem 0 1rem;
  margin-right: 1.2rem;
  margin-top: 1rem;
  cursor:pointer;
  display: inline-block;
}
.tag_btn {
  height: 3.2rem;
  font-size: 1.4rem;
  color: #696E7E;
  line-height: 3.2rem;
  text-align: center;
  border-radius: 1.6rem;
  background-color: rgba(243, 245, 248, 1);
  /*border: 1px solid #A4ACB4;*/
  outline: none;
  padding: 0 1rem 0 1rem;
  margin-right: 1.2rem;
  margin-top: 1rem;
  cursor:pointer;
  display: inline-block;
}
