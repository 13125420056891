.detailContainer {
    height: 100%;
    width: 76%;
    margin: 12.6rem auto;
    background-color: white;
    padding: 4rem 5rem;
}

.detailTitle {
    font-size: 3.4rem;
    font-weight: 600;
    color: #162533;
    line-height: 4.8rem;
}

.detailUserInfo {
    display: flex;
    margin-top: 1rem;
}

.detailUserAvatar {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
}

.detailUserName {
    font-size: 1.6rem;
    font-weight: 400;
    color: #162533;
    line-height: 3rem;
    margin-left: 1rem;
}

.detailUserReadCount {
    font-size: 1.4rem;
    font-weight: 400;
    color: #A4ACB4;
    line-height: 3rem;
    margin-left: 3rem;
}

.detailUserUpdateTime {
    font-size: 1.4rem;
    font-weight: 400;
    color: #A4ACB4;
    line-height: 3rem;
    margin-left: 3.3rem;
}

.detailTabs {
    margin-top: 4rem;
}

.tabTitle {
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.8rem;
}

.tabCreateTime {
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
}

.detailTabPane {
    display: flex;
    flex-direction: row;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
}
.detailTabPaneItem {
    /*flex-grow: 1;*/
}

.detailTabPaneItemMolecular {
    margin-top: 3rem;
    display: flex;
    font-size: 1.6rem;
    font-weight: 400;
    color: #162533;
    /*line-height: 22px;*/
    min-width: 33%;
    align-items: center;
}

.detailTabPaneItemAtom {
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    margin-right: 1.6rem;
}
.questionSpan {
    padding: 0 1rem;
    height: 3.2rem;
    line-height: 3.2rem;
    margin-right: 2.4rem;
    border: 1px solid #DCDFE6;
    border-radius: .4rem;
}

.detailTypes {
    margin-top: 4.6rem;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
}

.detailTypesItem {
    margin-right: 1.4rem;
}

.detailEndFlag {
    text-align: center;
    margin-top: 5rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #879099;
    line-height: 22px;
}

:global .ant-tabs-ink-bar {
    height: .8rem !important;
    color: #40A6FF;
}

.page_view {
    display: flex;
    justify-content: flex-end;
    padding-top: 5rem;
  }
.comments_reply {
    border: 1px solid #DCDFE6;
    padding: 2rem;
    margin-top: 3rem;
    margin-left: 5.2rem;
}
.comments_count {
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.8rem;
    border-bottom: 1px solid #DCDFE6;
    margin-top: 7.2rem;
    padding-bottom: 1.5rem;
}
.reply_count {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333333;
    line-height: 2.2rem;
}
.container {
    width: 100%;
    height: 100%;
}
