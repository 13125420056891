.second-contain {
    position: relative;
}
.second-img {
    width: 100%;
    height: auto;
}
.second-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 8%;
    z-index: 99;
}
.second-title {
    font-size: 7.2rem;
    font-weight: 600;
    color: #000000;
    line-height: 10rem;
    letter-spacing: .5rem;
}
.second-sub {
    font-size: 3.6rem;
    color: #333333;
    line-height: 5rem;
    margin-top: 3.2rem;
}