.six-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}

.contain-wrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  padding: 20rem 0;
}

.download-title {
  font-size: 6rem;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0;
}

.download-wrap {
  display: flex;
  width: 60rem;
  justify-content: space-between;
  margin-top: 7.8rem;
}

.download-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-text {
  font-size: 3.6rem;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 1.5rem;
  line-height: 5rem;
}

.download-icon {
  width: 20rem;
  height: 20rem;
}
