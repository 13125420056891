/*:global .ant-modal-body {*/
/*    padding: 0 !important;*/
/*}*/
/*:global .ant-modal-content {*/
/*    background-color: transparent !important;*/
/*}*/
.showMobilePreview {
    /*z-index: 9999;*/
    width: 39.1rem;
    height: 76.8rem;
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*opacity: 1;*/
    text-align: center;
}
.mobile_preview_header {
    /*display: block;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    height: 4rem;
    width: 38.7rem;
    background: #eeeff2;
    text-align: center;
    line-height: 4rem;
    border-top-right-radius: 5rem;
    border-top-left-radius: 5rem;
}

.mobile_preview_header_icon {
    display: inline-block;
    width: 6.5rem;
    height: 1rem;
    background: #c8c9cc;
    border-radius: .9rem;
    vertical-align: middle;
    margin-top: 1.8rem;
}

.mobile_preview_frame {
    width: 38.7rem;
    min-height: 29.4rem;
    height: 66.7rem;
    max-height: calc(100vh - 16.6rem);
    /*top: 40px;*/
    /*left: 0;*/
    border: 6px solid #eeeff2;
    /*position: relative;*/
    background-color: #fff;
    display: block;


    text-align: left;
    padding: 0 1.6rem;
    /*height: 637px;*/
    overflow: scroll;
}

.YuFrameMobilePreview {
    border: none;
    width: 37.5rem;
    height: 100%;
}

.mobile_preview_footer {
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 10px;*/
    /*left: 0;*/
    height: 6.2rem;
    width: 38.7rem;
    background: #eeeff2;
    text-align: center;
    line-height: 4.5rem;
    border-bottom-right-radius: 5rem;
    border-bottom-left-radius: 5rem;
}

.mobile_preview_footer_icon {
    display: inline-block;
    width: 4.3rem;
    height: 4.3rem;
    background: #c8c9cc;
    border-radius: 50%;
    vertical-align: middle;
}
.innerhtml {
    /*text-align: left;*/
    padding: 16px 0;
    /*height: 637px;*/
    /*overflow: scroll;*/
}
.innerhtml img {
    max-width: 34.3rem;
    height: auto !important;
}
.headerWrap {
    padding: 2.6rem 1rem 0 1rem;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.headerTitle {
    /*width: 340px;*/
    /*font-size: 21px;*/
    font-weight: 600;
    font-size: 1.7rem;
}
.treatmentItem {
    border-bottom: .4rem solid #40A6FF;


}
.treatmentTime {
    font-size: 1.2rem;
    margin-bottom: .4rem;
}
.infoWrap {
    margin-top: 1.7rem;
    display: inline-flex;
    flex-wrap: wrap;
}
.cureItem {
    display: inline-flex;
    margin-bottom: .4rem;
    min-width: 50%;
}
.cureTitle {
    font-size: 1.3rem;
    margin-right: .4rem;
    color: #A4ACB4;
}
.cureDetail {
    font-size: 1.3rem;
    color: #162533;
}
.cureInfoFlex {
    display: inline-flex;
    margin-bottom: .4rem;
}
.cureInfo {
    width: 50%;
    display: inline-flex;
}
.evalWrap {
    margin: 2rem 0px 0 0px;
    border: 1px solid rgba(64,166,255,0.5);
    border-radius: .8rem;
}
.evalTop {
    margin-top: 1.7rem;
    margin-left: 1.4rem;
    display: inline-flex;
    align-items: center;
}
.evalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #162533;
}
.evalTip {
    font-size: 1.3rem;
    margin-left: 1rem;
    color: #A4ACB4;
}
.hosInfo {
    margin-top: 2rem;
    background-color: rgba(64,166,255,0.05);
}
.hosItem {
    padding: 1.4rem 1.5rem;
    display: inline-flex;
    width: 100%;
}
.hosImg {
    width: 6.3rem;
    height: 4.8rem;
    margin-right: 1rem;
    overflow: hidden;

}
.hosImg :global .ant-image-img {
    width:100%;
    height: 100%;
    object-fit:cover;
}
.hosAdsWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.hosAds {
    width: 100%;
    margin-top: 1.2rem;
    display: inline-flex;
    justify-content: space-between;
}
.hosName {
    flex: 1;
    font-size: 1.5rem;
    font-weight: 600;
}
.hosLever {
    font-size: 1.2rem;
    color: #A4ACB4;
}
.noteTypeWrap {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 1.6rem;
}
.noteTag {
    background-color: #F5F7FA;
    border-radius: 1.8rem;
    height: 2.2rem;
    padding: 0 .9rem;
    margin: .5rem 2rem 0 0;
    display: inline-flex;
    align-items: center;
}
.noteType {
    font-size: 1.3rem;
    color: #A4ACB4;
}
.process {
    margin-top: 1.6rem;
}
.lineLabel {
    font-size: 1.5rem;
    font-weight: bold;
}
.lineText {
    font-size: 1.3rem;
    color: #696E7E;
    margin-left: .3rem;
}
.noteTitleView {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.6rem 0;
    width: -webkit-fill-available;
}
.noteTitleSpe {
    height: 1px;
    width: 11rem;
    background-color: #E5E5E5;
}
.bgLiner {
    position: absolute;
    bottom: .4rem;
    height: .8rem;
    width: 5rem;
    background: linear-gradient(270deg, rgba(255,181,108,0) 0%, #FF681D 100%);
}
.titleText {
    font-size: 1.8rem;
    font-weight: bold;
}
