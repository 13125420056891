.cardContent {
    display: flex;
}
.cardContentContainner {
    margin-top: 3rem;
    border: 1px solid #DCDFE6;
    padding: 3rem;
    border-radius: .8rem;
}


.cardImage {
    width: 14rem;
    height: 14rem;
    border-radius: 7rem;
    margin-right: 5.2rem;
    margin-left: 3.2rem;
    margin-top: 1.3rem;
    object-fit: cover;
}

.cardInfo {
    flex: 2;
    padding-left: 2rem;
}

.cardScore {
    display: inline-flex;
    flex-wrap: wrap;
    width: 90%;
}

.cardScoreItem {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
    display: flex;
    min-width: 33%;
}

.cardScoreItemIcon {
    width: 2rem;
    height: 2rem;
    margin-left: 3rem;
}

.cardScoreItemText {
    margin-left: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #40A6FF;
    line-height: 2.2rem;
}

.cardDoctorTitle {
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.8rem;
}

.cardDoctorLevel {
    margin-top: 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
}

.cardDoctorCommented {
    font-size: 2rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
}

.cardDoctorTotal {
    margin-top: 4.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #162533;
    line-height: 2.2rem;
    display: flex;
}

.cardDoctorTotalStatus {
    font-size: 1.6rem;
    /*font-weight: 600;*/
    color: #40A6FF;
    line-height: 2rem;
}

.cardDoctorLabel {
    display: flex;
    margin-top: 1.8rem;
    flex-wrap: wrap;
    max-width: 41rem;
}

.cardDoctorLabelItem {
    margin-right: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2rem;
    border-radius: .2rem;
    border: 1px solid #DCDFE6;
    padding: .3rem .7rem;
}
