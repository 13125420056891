html,body{
  height: 100%;
  margin: 0;
}
.ant-list-item {
  border: none !important;
  padding: 0!important;
}
body {
  background-color: rgb(220,223,230,0.3) !important;
}
.container {
  width: 100%;
  height: 100%;
  padding-top: 10rem;
  overflow: auto;
}
.scroll_view {
  width: 100%;
  overflow: auto;

}
.title {
  margin: 0 auto;
  width: 76%;
  font-size: 1.6rem;
  color: #A4ACB4;
  line-height: 2.2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 2.6rem;
  padding-bottom: 1.7rem;
  background-color: white;
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem;
}
.img_view {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 3.4rem;
  background-color: white;
}
.img_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img_title {
  font-size: 2rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.8rem;
}
.btn_wrap {
  display: flex;
  align-items: center;
}
.mg_btn {
  width: 6rem;
  height: 3rem;
  font-size: 1.4rem;
  line-height: 2.8rem;
  text-align: center;
  color: #696E7E;
  background: #FFFFFF;
  border-radius: .4rem;
  border: 1px solid #A4ACB4;
  outline: none;
  padding: 0;
  margin: 0;
  cursor:pointer;
}
.del_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 3rem;
  background: #FFFFFF;
  border-radius: .4rem;
  border: 1px solid #A4ACB4;
  cursor:pointer;
}
.del_icon {
  width: 1.7rem;
  height: 1.5rem;
  margin-right: .3rem;
}
.del_txt {
  font-size: 1.4rem;
  color: #696E7E;
  line-height: 2rem;
}
.select_btn {
  width: 6rem;
  height: 3rem;
  line-height: 2.8rem;
  font-size: 1.4rem;
  text-align: center;
  color: #696E7E;
  background-color: #FFFFFF;
  border-radius: .4rem;
  border: 1px solid #A4ACB4;
  outline: none;
  margin-right: 1rem;
  cursor:pointer;
}
.timer {
  font-size: 1.6rem;
  color: #A4ACB4;
  line-height: 2.2rem;
  padding-bottom: .8rem;
  padding-top: .8rem;
  text-align: left;
}
.img_wrap {
  display: flex;
  flex-wrap: wrap;
}
.img_item {
  position: relative;
  padding-right: 1.6rem;
  padding-bottom: 1.6rem;
}
.img_del {
  width: 17rem;
  height: 17rem;
}
.select_icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 2.6rem;
  cursor:pointer;
}
.loading_wrap {
  display: flex;
  flex: 1;
  justify-content: center;
}
.loadding_icon {
  width: 3.5rem;
  height: 3.5rem;
}
.emptyView {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.emptyIcon {
  width: 33.1rem;
  height: 19.6rem;
  margin-top: 20.8rem;
}
.emptyText {
  font-size: 1.6rem;
  color: #162533;
  line-height: 2.2rem;
  margin-top: 1rem;
}
.big_load {
  width: 14rem;
  height: 14rem;
  margin-top: 20rem;
}

