/* .ant-pagination-options {
  display: none;
} */

.ant-form-item-label {
  width: 0;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;

}
.news_contant {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 76%;
  min-height: 65rem;
  border-radius: .8rem;
  margin-top: 2.6rem;
  overflow-y: scroll;
}
.header_wrap {
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.8rem;
  border-bottom: 1px solid #DCDFE6;
  padding-top: 5.3rem;
  padding-bottom: 1.5rem;
  margin: 0 5rem 1.7rem 5rem;
}
.news_wrap {
  padding-bottom: 2.4rem;
}
.news_header {
  display: flex;
  align-items: center;
  margin-left: 5rem;
  margin-right: 5rem;
}
.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  overflow: hidden;
  margin-right: 1.2rem;
}
.name_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user_name {
  font-size: 1.4rem;
  color: #162533;
  line-height: 2rem;
}
.user_time {
  font-size: 1.2rem;
  color: #A4ACB4;
  line-height: 1.7rem;
}
.reply_wrap {
  padding-left: 10.2rem;
  padding-right: 5rem;
  margin-top: 1rem;
}
.reply_tip {
  font-size: 1.6rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.2rem;
}
.reply_del {
  font-size: 1.6rem;
  color: #162533;
  line-height: 2.2rem;
}
.like_reply {
  display: flex;
  align-items: center;
  padding-left: 10.2rem;
  padding-right: 5rem;
  margin-top: 1.1rem;
}
.like_wrap {
  display: flex;
  align-items: center;
  margin-right: 4.8rem;
  cursor:pointer;
}
.like_icon {
  width: 1.6rem;
  height: 1.4rem;
  margin-right: .6rem;
}
.like_text {
  font-size: 1.4rem;
  color: #162533;
  line-height: 2rem;
  cursor:pointer;
}
.note_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 1048px; */
  height: 5rem;
  border-radius: .2rem;
  background-color: #F3F5F8;
  margin-left: 10.2rem;
  margin-right: 5rem;
  margin-top: 1.8rem;
}
.input_wrap {
  /* width: 1048px; */
  margin-left: 10.2rem;
  margin-right: 5rem;
  margin-top: 1.6rem;
}
.btn_wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.btn_item {
  width: 12rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  color: white;
  text-align: center;
  background-color: #40A6FF;
  border-radius: .4rem;
  padding: 0;
  margin-left: 2rem;
  border: none;
  outline: none;
  cursor:pointer;
}
.note_text {
  font-size: 1.6rem;
  color: #696E7E;
  line-height: 2.2rem;
  margin-left: 2rem;
}
.grey_goin {
  width: .8rem;
  height: 1.2rem;
  margin-right: 1.9rem;
}
.page_view {
  margin: 0 auto;
  width: 76%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5rem;
  padding-bottom: 6rem;
}
.empty_view {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.empty_icon {
  width: 33.1rem;
  height: 19.6rem;
  margin-top: 20.8rem;
}
.emptyText {
  font-size: 1.6rem;
  color: #162533;
  line-height: 2.2rem;
  margin-top: 1rem;
}

