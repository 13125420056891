.menu {
  width: 100%;
  height: 8rem;
  background:rgba(255,255,255,1);
  box-shadow:0px 3px 6px 0px rgba(217,217,217,0.5);
  position: fixed;
  top: 0;
  z-index: 999;
}
.menu_wrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0 12%;
}


.menu_left {
  display: flex;
  align-items: center;
}
.menu_right {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.logo{
  width: 13.8rem;
  height: 6.2rem;
  margin-right: 4rem;
}

.title {
  font-size: 3rem;
  font-weight: 600;
  color: #40A6FF;
}
.actions{
  margin: 0;
  padding: 0;
  display:flex;
  height: 100%;
  list-style-type: none;
}
.actions li {
  display: flex;
  align-items: center;
  height: 100%;
 
  margin-right: 4rem;
}


.btn_wrap  {
  height: 100%;
  display: flex;
  align-items: center;
}
.sign_btn {
  font-size: 2rem;
  color: #666666;
  padding: .5rem 1rem;
  background: transparent;
  border: none;
  outline: none;
  cursor:pointer;
}
.link_word {
  display:flex;
  align-items: center;
  font-size: 2rem;
  color: #666666;
  text-decoration:none;
  padding-top: .4rem;
  padding-bottom: .4rem;
  border-bottom-style: solid;
  border-bottom-width: .4rem;
  border-bottom-color: #FFFFFF;
}
.active .link_word {
  border-bottom-color: #000000;
}
.link_word:hover {
  color: #40A6FF;
  cursor: pointer;
}

li.active .link_word {
  color: #000000;
  font-weight: 600;
}
.triangleWrap {
  display: flex;
  align-items: center;
}
.user_wrap {
  height: 100%;
  position: relative;
}
.user_info {
  display: flex;
  align-items: center;
  height: 100%;
}
.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  margin-right: 1.2rem;
  overflow: hidden;
}
.center_text {
  font-size: 2rem;
  line-height: 2.2rem;
  color: #162533;
}
.modal_view {
  /* background: rgba(0,0,0,0.8);
  border-radius: .8rem; */
  /* overflow: hidden; */
  position: absolute;
  top: 80%;
  left: 6rem;
}
.modal_view::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 0.8rem;
  border-right: 1.6rem;
  border-bottom: 1rem;
  border-left: 1.6rem;
  border-color: transparent transparent rgba(0,0,0,0.8) transparent ;
  border-style: solid;
  position: absolute;
  top: -1.6rem;
  left: 3.5rem;
}
.modal_item {
  width: 9.6rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m_t_12 {
  margin-top: 1.2rem;
}
.modal_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color: #FFFFFF;
}
.new_text {
  width: 2.7rem;
  height: 1.8rem;
  line-height: 1.8rem;
  font-size: 1.2rem;
  text-align: center;
  color: white;
  background: #F16765;
  border-radius: .9rem .9rem .9rem 0px;
  position: absolute;
  right: .9rem;
  top: -.2rem;
}
.triangle {
  width: 13.7rem;
  height: 1.3rem;
  position: absolute;
}
.triangle_icon {
  width: 1.6rem;
  height: 1.6remx;
  margin-left: 1rem;
}
.transfromY {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1rem;
  transform: rotate(180deg);
}

.modal1_view {
  position: absolute;
  top: 80%;
}
.manage {
  background: rgba(0,0,0,0.8);
  border-radius: .8rem;
  overflow: hidden;
}
.manage_word {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.6rem;
  height: 5rem;
  font-size: 1.6rem;
  color: #FFFFFF;
  background: rgba(0,0,0,0.8);
}
.line1 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  border: 1px solid #FFFFFF;
}
.manage .active .manage_word  {
  color: #40A6FF;
}
.modal1_view::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 0.8rem;
  border-right: 1.6rem;
  border-bottom: 1rem;
  border-left: 1.6rem;
  border-color: transparent transparent rgba(0,0,0,0.8) transparent ;
  border-style: solid;
  position: absolute;
  top: -1.6rem;
  left: 3.6rem;
}