html,body{
  height: 100%;
  margin: 0;
  
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  overflow: auto;
  font-size: 0;
}
.about_top {
  width: 100%;
  height: auto;
}
.about_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12rem;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 30.5rem;
  background-color: #fff;
}
.title {
  font-size: 3.6rem;
  font-weight: 600;
  color: #000000;
  line-height: 5rem;
  border-bottom: .6rem solid #40A6FF;
}
.info_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4.2rem;
}
.company {
  width: 30.3%;
  height: auto;
  flex-shrink: 0;
}
.info_text {
  flex: 1;
  margin-right: 4%;
  font-size: 2rem;
  color: #000000;
  line-height: 3.6rem;
  text-indent: 4rem;
  margin-top: 1rem;
}
.m_t_60 {
  margin-top: 6rem;
}
.m_t_150 {
  margin-top: 15rem;
}
.connect {
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-top: 8.8rem;
}
.connect_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.connect_icon {
  width: 8rem;
  height: auto;
}
.connect_title {
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 600;
  color: #000000;
  line-height: 2.8rem;
}
.connect_text {
  margin-top: .8rem;
  font-size: 1.6rem;
  color: #333333;
  line-height: 2.2rem;
}