html,body{
  height: 100%;
  margin: 0;
}
body {
  background-color: rgb(220,223,230,0.3) !important;
}
.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
}
.user_wrap {
  margin: 0 auto;
  /* width: 795px; */
  background: #FFFFFF;
  border-radius: .8rem;
  margin-top: 2.8rem;
  margin-left: 12%;
  margin-right: 12%;
  margin-bottom: 6rem;
}
.user_info {
  padding: 5rem 5.2rem 3.5rem 5.2rem;
}
.title_wrap {
  display: flex;
  align-items: center;
  border-bottom: .1rem solid #DCDFE6;
  padding-bottom: 1.5rem;
}
.title {
  font-size: 2rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.8rem;
  margin-right: .9rem;
}
.sub_title {
  font-size: 1.4rem;
  font-weight: 400;
  color: #696E7E;
  line-height: 2rem;
}
.user_del {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.9rem;
  padding: 2rem 1.4rem;
  background-color: #F3F5F8;
  border-radius: .5rem;
}
.user_title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.2rem;
}
.flex_box {
  display: flex;
  align-items: center;
}
.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  margin-right: .9rem;
  overflow: hidden;
}
.user_text {
  font-size: 1.6rem;
  font-weight: 400;
  color: #696E7E;
  line-height: 2.2rem;
}
.input_wrap {
  display: flex;
  align-items: center;
  width: 69.2rem;
  height: 5rem;
  border-radius: .5rem;
  border: .1rem solid #A4ACB4;
  overflow: hidden;
  margin-top: 2rem;
}
.input_title {
  display: inline-block;
  width: 10.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #162533;
  line-height: 2.2rem;
  padding-left: 2rem;
}
.input_view {
  width: 56.4rem;
  height: 100%;
  line-height: 5rem;
  font-size: 1.6rem;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
}
.err_tip {
  display: block;
  height: 2.5rem;
  font-size: 1.4rem;
  color: #F16765;
  line-height: 2rem;
  margin-top: .7rem;
}
.save_btn {
  width: 18.7rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  color: white;
  background-color: #40A6FF;
  /* background-color: #DCDFE6; */
  border-radius: .5rem;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-top: 1.2rem;
  cursor:pointer;
}
.wechat_wrap {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
}
.wechat {
  width: 3.7rem;
  height: 3rem;
  margin-right: 1.1rem;
}
.wechat_tip {
  font-size: 1.6rem;
  color: #162533;
  line-height: 2.2rem;
}
.wechat_btn {
  width: 10rem;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: center;
  font-size: 1.6rem;
  color: white;
  background-color: #40A6FF;
  border-radius: .4rem;
  outline: none;
  border: none;
  cursor: pointer;
}