.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: transparent !important;
  z-index: 9999;
}
.modal_view {
  width: 39.1rem;
  height: 34rem;
  background: #ffffff;
  border-radius: .8rem;
}
.close_wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.close_item {
  padding: 1.6rem;
  padding-bottom: 0;
  margin-top: 1rem;
  margin-right: 1.1rem;
  margin-bottom: .2rem;
}
.close_icon {
  width: 1.6rem;
  height: 1.6rem;
}
.content_wrap {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.title {
  font-size: 2rem;
  font-weight: 600;
  color: #333333;
  line-height: 2.8rem;
  margin-bottom: 2.6rem;
}
.input_wrap {
  width: 31.9rem;
  height: 5rem;
  border: .1rem solid #DCDFE6;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
  overflow: hidden;
}
.num_input {
  width: 30.5rem;
  height: 5rem;
  font-size: 1.8rem;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 1.4rem;
}
.input_wrap_bottom {
  display: flex;
  align-items: center;
  width: 31.9rem;
  height: 5rem;
  border: .1rem solid #DCDFE6;
  border-top: 0;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  overflow: hidden;
}
.code_input {
  width: 20.3rem;
  height: 5rem;
  font-size: 1.8rem;
  color: #162533;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 1.4rem;
}
.line_icon {
  width: .1rem;
  height: 2rem;
  background-color: #DCDFE6;
}
.code_btn {
  width: 10rem;
  height: 5rem;
  font-size: 1.4rem;
  color: #162533;
  background-color: transparent;
  border: none;
  outline: none;
}
.err_tip {
  height: 1.7rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #F16765;
  line-height: 1.7rem;
  margin-top: 1.1rem;
}
.submit_btn {
  width: 31.9rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  color: #FFFFFF;
  background: #40A6FF;
  border-radius: .4rem;
  border: none;
  outline: none;
  margin-top: 1.5rem;
}