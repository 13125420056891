.imgView {
  width: 120rem;
  height: 80rem;
  position: relative;
  overflow: auto;
}
.imgItem {
  width: 100%;
  height: 100%;
}
.closeIcon {
  width: 3.6rem;
  height: 3.6rem;
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
}