.ant-list-split .ant-list-header {
  padding: 0;
  border: none;
}
.antd-form .ant-form-item-label {
  width: 0;
}
.ant-pagination-item {
  min-width: 3.2rem;
  height: 3.2rem;
  margin-right: .8rem;
  line-height: 3rem;
  border-radius: .2rem;
}
.ant-pagination-item a {
  padding: 0 .6rem;
}
.ant-pagination-prev, 
.ant-pagination-next, 
.ant-pagination-jump-prev, 
.ant-pagination-jump-next {

  min-width: 3.2rem;
  height: 3.2rem;
  line-height: 3rem;
  border-radius: .2rem;
}
.ant-pagination-prev
.ant-pagination-item-link, 
.ant-pagination-next 
.ant-pagination-item-link {
  font-size: 1.2rem;
  border-radius: .2rem;
}
.ant-pagination-item a,
.ant-pagination .ant-select,
.ant-form label,
.ant-checkbox-wrapper {
  font-size: 1.6rem;
}

.ant-pagination-options .ant-select-selection-search-input,
.ant-pagination-options .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 3.2rem;
}
.ant-pagination-options .ant-select-single .ant-select-selector .ant-select-selection-item, 
.ant-pagination-options .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 3.2rem;
}






