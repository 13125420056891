.imgMenu {
    color: #162533;
    text-align: center;
}
.imgMenu>:first-child {
    border-bottom: .1rem solid #DCDFE6;
}
.imgMenuItem {
    background-color: #ffffff;
    height: 4.2rem;
    line-height: 4.2rem;
    width: 100%;
}
:global .bf-container .public-DraftEditor-content {
    min-height: 21rem;
}
:global .bf-dropdown .dropdown-handler .bfi-drop-down {
    width: auto;
}
:global .bf-dropdown .dropdown-content {
    width: 16.7rem;
}
:global .bf-dropdown .dropdown-content .dropdown-arrow {
    background-color: white;
}
:global .bf-dropdown .dropdown-content-inner {
    background-color: white;
}
.imgModalItem {
    width: 19.3rem;
    height: 19.3rem;
    border-radius: .3rem;
    border: 1px solid #DCDFE6;
}
.imgModalItemTxt {
    height: 2rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #162533;
    line-height: 2rem;
    text-align: center;
    margin-top: 1.4rem;
}
:global .ant-modal-body {
    padding: 3.5rem;
}
.picTit {
    font-size: 2rem;
    font-weight: 600;
    color: #333333;
    line-height: 2.8rem;
    margin-top: 1rem;
}
.picSub {
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
    margin-top: 1.7rem;
    margin-bottom: 2.9rem;
}
:global .ant-upload {
    width: 100%;
    height: 100%;
}
.uploadButton {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}
.titleLeft {
    color: #696E7E;
    font-size: 1.4rem;
    margin-bottom: 3rem;
}
.titleLeftRig {
    color: #162533;
    font-weight: 600;
}
.imgCon {
    width: 18rem;
    height: 18rem;
    position: relative;
}
.imgRight {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    border-radius: 50%;
    border: 1px solid #696E7E;
}
.btmSty {
    height: 10rem;
}
.btmBtn {
    width: 31.9rem;
    height: 5rem;
    border-radius: .4rem;
    border: 1px solid #DCDFE6;
    color: #162533;
}
.btmCol {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.btmBtnSure {
    width: 31.9rem;
    height: 5rem;
    background: #40A6FF;
    border-radius: .4rem;
    color: #FFFFFF;
    border-color: transparent;
}
.customEditor :global .bf-content * {
    line-height: 2.4rem;
}
