.container {
    padding: 2rem;
    border-radius: .4rem;
    border: 1px solid #DCDFE6;
    display: flex;
    margin-top: 2.9rem;
}

.container-title {
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    color: #333333;
    line-height: 2.8rem;
}

.card-left-bottom {
    display: flex;
}
.card-left-footer {
    margin-top: 1.5rem;
}

.card-delete-btn {
    margin-right: 2rem;
}

.card-status {
    margin-right: 2rem;
}

.card-time {
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
}

.card-review {
    font-size: 1.6rem;
    font-weight: 600;
    color: #40A6FF;
    line-height: 2.2rem;
}

.card-success {
    font-size: 1.6rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.2rem;
}

.card-failed {
    font-size: 1.6rem;
    font-weight: 600;
    color: #F16765;
    line-height: 2.2rem;
}

.card-delete {
    font-size: 1.6rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.2rem;
}

.container-left {
    width: 80%;
}
.container-left-footer {
    display: flex;
    height: 85%;
    justify-content: space-between;
}

.image-card {
    width: 24rem;
    height: 18rem;
    background-size: cover;
    background-position: center center;
}
.card-btn-class {
    width: 12rem;
    height: 4rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    border-radius: .4rem;
}

.card-btn-review {
    color: #FFFFFF;
}

.card-left-flex{
    align-self: flex-end;
}

.margin-right-20 {
    margin-right: 2rem;
}