.reply_item {
    width: 100%;
    margin-top: 2.8rem;
}
.header_view {
    display: flex;
    align-items: center;
}
.avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    margin-right: 1.2rem;
}
.name_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.reply_wrap {
    display: flex;
    margin-top: 1rem;
    padding-left: 5.2rem;
}
.reply_tip {
    font-size: 1.6rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.2rem;
}
.reply_del {
    font-size: 1.6rem;
    color: #162533;
    line-height: 2.2rem;
}
.like_reply {
    display: flex;
    align-items: center;
    padding-left: 5.2rem;
    margin-top: 1rem;
}
.like_wrap {
    display: flex;
    align-items: center;
    margin-right: 4.8rem;
    cursor:pointer;
}
.like_icon {
    width: 1.6rem;
    height: 1.4rem;
    margin-right: .6rem;
}
.like_text {
    font-size: 1.4rem;
    color: #162533;
    line-height: 2rem;
    cursor:pointer;
}
.input_wrap {  
    margin-left: 5.2rem;
    margin-top: 1.5rem;     
}
.btn_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}
.btn_item {
    width: 12rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.6rem;
    color: white;
    text-align: center;
    background-color: #40A6FF;
    border-radius: .4rem;
    padding: 0;
    margin-left: 2rem;
    border: none;
    outline: none;
    cursor:pointer;
}