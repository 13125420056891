.container {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
}
.service_top {
  width: 100%;
  height: auto;
}
.service_wrap {
  width: 100%;
  background-color: #fff;
  padding-bottom: 4rem;
}
