.first-contain {
    position: relative;
}
.first-img {
    width: 100%;
    height: auto; 
}

.first-title-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 25%;
    z-index: 99;
}
.first-title {
    font-size: 7.2rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 10rem;
    letter-spacing: 1.8rem;
}
.first-sub {
    font-size: 3.6rem;
    color: #FFFFFF;
    line-height: 5rem;
    margin-top: 2.8rem;
}
.first-download {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 18.6%;
    z-index: 99;
}
.download-btn {
    width: 30rem;
    height: 8rem;
    font-size: 2.4rem;
    font-weight: 600;
    color: #FFFFFF;
    background: #40A6FF;
    border-radius: .8rem;  
    outline: none;
    border: none;
}
.m-r-74 {
    margin-right: 7.4rem;
}
.download-item {
    position: relative;
}
.d-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29rem;
    height: 29.8rem;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px 0px rgba(134,167,187,0.8);
    position: absolute;
    top: -32rem;
    left: .5rem;
    border-radius: .8rem;
}
.d-icon img {
    width: 24rem;
    height: 24rem;
}
.triangle {
    padding: .6rem;
    position: absolute;
    transform: rotate(90deg);
    bottom: 0;
    
}
.triangle::before {
    content: '';
    width: 0;
    height: 0;
    border-width: .8rem 0 .8rem .8rem;
    border-style:dashed;
    border-color: transparent transparent transparent rgba(134,167,187,0.8);
    position: absolute;
    top: .5rem;
    right: -.8rem;
    filter: blur(2.5rem);
} 
.triangle::after {
    content: '';
    width: 0;
    height: 0;
    border-width: .8rem 0 .8rem .8rem;
    border-style:dashed;
    border-color:transparent transparent transparent #fff;
    position: absolute;
    top: .5rem;
    right: -.7rem;
}

  

