.container {
    height: 100%;
    width: 76%;
    margin: 10rem auto;
    background-color: white;
}

.ant-tabs-nav{
    margin: 0;
}

:global .ant-tabs-nav{
    margin: 0 !important;
}

.pagination {
    padding: 6rem 0;
    float: right;
}

.tabContent {
    padding: 0 5rem;
    padding-bottom: 5.6rem;
}

.emptyView {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
.emptyIcon {
    background-color: white;
    width: 33.1rem;
    height: 19.6rem;
    margin-top: 8.4rem;
}
.emptyText {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #162533;
    line-height: 2.2rem;
}

.empytBtn {
    margin-top: 5rem;
    border-radius: .4rem;
    width: 27rem;
    height: 5rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: #FFFFFF;
}