.container {
    width: 100%;
    height: 100%;
    padding-top: 8rem;
}
.user_wrap {
    margin: 0 auto;
    width: 80%;
    /* width: 1200px; */
    /*height: 569px;*/
    /*background: #FFFFFF;*/
    border-radius: .8rem;

}
.user_wrap_inner {
    /*margin: 0 auto;*/
    /*width: 1200px;*/
    /*height: 569px;*/
    background: #FFFFFF;
    /*border-radius: 8px;*/
    margin-top: 2.8rem;
    padding-top: 4rem;
    padding-bottom: 5.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
.editContainer {
    background: #FFFFFF;
    /*border-radius: 8px;*/
    margin-top: 2.8rem;
    padding-top: 4rem;
    padding-bottom: 5.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
:global .ant-form-item{
     margin-bottom: 0;
}
.line1 {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.title_text {
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
}
.trash_text {
    font-size: 1.4rem;
    font-weight: 400;
    color: #696E7E;
}
.rightArrow {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: .2rem;
    margin-bottom: .1rem;
}
.titlt_input {
    height: 5.5rem;
}
.titlt_input input{
    font-size: 3rem;
}
.title_shenyu {
    font-size: 2rem;
    color: #A4ACB4;
    line-height: 2.8rem;
}
.checkItem {
    width: 10rem;
}
.divider {
    margin: 0 0;
}
.line2 {
    display: inline-flex;
    width: 100%;
    align-items: center;
    height: 6.8rem;
}
.type_title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #162533;
    min-width: 10rem;
}
.type_title :after {
    content: none;
}
.type_title :before {
    display: none !important;
}
.titlt_input_type {
    width: 100%;
}

.divider_type {
    margin: 0 0;
}
.colContent {
   width: 95% !important;
}
.titleWithMar {
    font-size: 1.8rem;
    font-weight: 600;
    color: #162533;
    margin-bottom: .7rem;
}
.titleExtra {
    margin-left: 2.4rem;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: none;
}
.titleMoney {
    color: #FF4340;
    font-size: 1.5rem;
}
.choose_row {
    margin-bottom: 2rem;
}
.fabuLine {
    height: 7rem;
    background-color: white;
    width: 100%;
    margin-top: 5.3rem;
    position: fixed!important;
    bottom: 0;
    z-index: 999;
}
.fabuLineContent {
    margin: 0 auto;
    /* width: 1200px; */
    /*background-color: red;*/
    padding-right: 10%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.saveAuto {
    margin-right: 20px;
    color: #40A6FF;
    font-size: 1.6rem;
}
.saveBtn {
    width: 18.7rem;
    height: 5rem;
    background: #FFFFFF;
    border-radius: .4rem;
    border: 1px solid #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #162533;
    font-size: 1.6rem;
    cursor:pointer;
}
.btnMar {
    margin: 0 1.5rem;
}
.fabuBtn {
    width: 29rem;
    background: #40A6FF;
    border-radius: .4rem;
    color: #ffffff;
}


.real_text {
    font-size: 1.6rem;
    font-weight: 400;
    color: #696E7E;
    line-height: 2.2rem;
}
.real_btn_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}
.real_btn {
    font-size: 1.4rem;
    color: white;
    background-color: #40A6FF;
    border-radius: .4rem;
    cursor: pointer;
    padding: .4rem .8rem;
    border: none;
    outline: none;
}
.iframeModal :global .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}
.unitText {
    line-height: 3.2rem;
    padding: 0 1.2rem;
}
