.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
    background-color: transparent !important;
    z-index: 9999;
}
.protocol_modal {
    width: 59.3rem;
    height: 60rem;
    background: #ffffff;
    border-radius: .8rem;
}
.close_wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.close_item {
    padding: 1.6rem;
    padding-bottom: 0;
    margin-top: .5rem;
    margin-right: .5rem;
    margin-bottom: .4rem;
}
.close_icon{
    width: 1.6rem;
    height: 1.6rem;
}
.title{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #162533;
    line-height: 2.8rem;
}
.content {
    width: 52.1rem;
    height: 36rem;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-left: 3.5rem;
}
.content::-webkit-scrollbar { 
    width: 0 !important 
}
.btn_wrap {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 4.6rem;
}
.confirm_btn {
    width: 31.9rem;
    height: 5rem;
    text-align: center;
    line-height: 5rem;
    font-size: 1.6rem;
    color: #FFFFFF;
    background: #40A6FF;
    border-radius: .4rem;
    border: none;
    outline: none;
}
.sub_title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #162533
}
.content_del {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #162533
}
.sub_content {
    margin-left: 2.8rem;
}
.sub_sub_content {
    margin-left: 5.6rem;
}