.five-contain {
    position: relative;
}
.five-img {
    width: 100%;
    height: auto;
}
.five-info {
    position: absolute;
    left: 18.75%;
    top: 33%;
    z-index: 99;
}
.five-title  {
    font-size: 7.2rem;
    font-weight: 600;
    color: #40A6FF;
    line-height: 10rem; 
}
.five-tip  {
    font-size: 3.6rem;
    color: #333333;
    line-height: 6rem;
    margin-top: 4rem;
}



